import { FC, PropsWithChildren, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { Check, Placeholder, X } from '@phosphor-icons/react';
import { AnimatePresence, useAnimate } from 'framer-motion';
import { twJoin, twMerge } from 'tailwind-merge';

import { Avatar } from '../Avatar';
import { Chevron } from '../Chevron';
import { InputText } from '../InputText';
import { Text } from '../Text';
import { SelectProvider } from './contexts/SelectContext';
import { useSelect } from './hooks/useSelect';
import { selectInputChevronVariants, selectOptionTitleVariants } from './styles';
import {
  ISelectContentProps,
  ISelectHelpTextProps,
  ISelectInputProps,
  ISelectInputRootProps,
  ISelectOption,
  ISelectOptionProps,
  ISelectOptionsProps,
  ISelectRootProps,
} from './types';

// ------------------------------------------

const SelectRoot = (props: ISelectRootProps): ReactElement => {
  const { children, className, label, value, options, disable } = props;

  return (
    <SelectProvider label={label} value={value} options={options} disable={disable}>
      <div className={twJoin('w-full', className)}>{children}</div>
    </SelectProvider>
  );
};

SelectRoot.displayName = 'Select.Root';

// ------------------------------------------

const SelectContent = (props: ISelectContentProps): ReactElement => {
  const { children } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const { closeSelectOpen, disable } = useSelect();

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent): void => {
      if (!contentRef.current?.contains(event.target as Node)) {
        closeSelectOpen();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeSelectOpen]);

  return (
    <div
      ref={contentRef}
      aria-disabled={disable}
      className="relative flex w-full select-none aria-disabled:cursor-not-allowed"
    >
      {children}
    </div>
  );
};

SelectContent.displayName = 'Select.Content';

// ------------------------------------------

const SelectFilter = (): ReactElement => {
  const { t } = useTranslation('globals');

  const { handleFilterOptions } = useSelect();

  const [filter, setFilter] = useState('');

  useDebounce(
    () => {
      handleFilterOptions(filter);
    },
    500,
    [filter],
  );

  return (
    <InputText.Root className="m-1">
      <InputText.InputRoot>
        <InputText.Input onChange={event => setFilter(event.target.value)} placeholder={t('typing_to_filter')} />
      </InputText.InputRoot>
    </InputText.Root>
  );
};

SelectFilter.displayName = 'Select.Filter';

// ------------------------------------------

const SelectInputRoot: FC<PropsWithChildren<ISelectInputRootProps>> = (props): ReactElement => {
  const { children, classNameContainer, clearable, hasError, onClearOption } = props;

  const { disable, handleOptionChange, isOpened, option, toggleSelectOpen, value } = useSelect();

  const optionValue = option ? option[value] : undefined;

  const onClearClick = (): void => {
    handleOptionChange({} as ISelectOption);

    if (onClearOption) {
      onClearOption();
    }
  };

  return (
    <button
      type="button"
      onClick={toggleSelectOpen}
      data-is-opened={isOpened}
      data-has-error={hasError}
      className={twJoin(
        'flex h-10 w-full cursor-pointer items-center rounded-lg border border-gray-200 px-4 transition-all data-[has-error=true]:border-red-500 data-[is-opened=true]:border-green-500 disabled:pointer-events-none dark:border-black-400',
        classNameContainer,
      )}
      disabled={disable}
    >
      {children}

      {clearable && optionValue && <X size={16} onClick={onClearClick} className="text-black-400 dark:text-gray-600" />}

      <Chevron height={11} onClick={toggleSelectOpen} className={selectInputChevronVariants({ disable, isOpened })} />
    </button>
  );
};

SelectInputRoot.displayName = 'Select.InputRoot';

// ------------------------------------------

const SelectInput = (props: ISelectInputProps): ReactElement => {
  const { inputRef, placeholder, value: valueProp, defaultValue, options = [] } = props;

  const valueDefault = valueProp ?? defaultValue;

  const { option, label, value, options: optionsCtx, handleOptionChange, disable } = useSelect();

  const opts = optionsCtx.length ? optionsCtx : options;

  const optionLabel = option ? option[label] : undefined;
  const optionValue = option ? option[value] : undefined;

  useEffect(() => {
    const optionDefaultSelected = opts.find(o => o[value] === valueDefault);

    if (optionDefaultSelected && !optionValue) {
      const optionDefaultLabel = optionDefaultSelected[label];
      const optionDefaultValue = optionDefaultSelected[value];

      handleOptionChange({ [label]: optionDefaultLabel, [value]: optionDefaultValue });
    }
  }, [defaultValue, opts, value, optionValue, label, handleOptionChange, valueDefault]);

  return (
    <input
      ref={inputRef}
      type="text"
      readOnly
      disabled={disable}
      placeholder={placeholder}
      defaultValue={optionLabel}
      data-value={optionValue}
      className="w-full flex-1 bg-transparent text-sm leading-none text-black-400 caret-green-500 outline-none placeholder:text-sm placeholder:text-gray-400 disabled:pointer-events-none disabled:text-gray-500 disabled:placeholder:text-gray-200 dark:text-white dark:placeholder:text-gray-500 dark:disabled:text-gray-700 dark:disabled:placeholder:text-black-400"
    />
  );
};

SelectInput.displayName = 'Select.Input';

// ------------------------------------------

const SelectOption = (props: ISelectOptionProps): ReactElement => {
  const { onSelectOption, option, icon: Icon, withAvatar } = props;

  const optionHasDescription = Object.keys(option).some(o => o === 'description');

  const { toggleSelectOpen, handleOptionChange, option: selected, value, label } = useSelect();

  const handleSelectOption = (opt: ISelectOption): void => {
    toggleSelectOpen();
    handleOptionChange(opt);

    if (onSelectOption) onSelectOption(option);
  };

  const isSelected = (opt: ISelectOption): boolean => {
    return !!selected && selected[value] === opt[value];
  };

  return (
    <button
      type="button"
      className="flex flex-col px-4 py-3 text-left transition-colors first:rounded-t-lg last:rounded-b-lg hover:bg-green-50 dark:hover:bg-green-500/10"
      onClick={() => handleSelectOption(option)}
    >
      <div className="flex items-center">
        {withAvatar && (
          <Avatar.Root classNameContainer="mr-2" mode="ROUNDED" size="xxs">
            <Avatar.Content icon={Icon ?? Placeholder} url={option.imageUrl} />
          </Avatar.Root>
        )}

        <Text className={selectOptionTitleVariants({ isSelected: isSelected(option) })}>{option[label]}</Text>

        {isSelected(option) && <Check size={16} className="ml-2 text-green-500" weight="bold" />}
      </div>

      {optionHasDescription && <Text className="mt-1 text-xs text-gray-500">{option.description}</Text>}
    </button>
  );
};

SelectOption.displayName = 'Select.Option';

// ------------------------------------------

const SelectOptions = (props: ISelectOptionsProps): ReactElement => {
  const { optionKey, options = [], withFilter, ...selectOptionProps } = props;

  const { t } = useTranslation('globals');

  const [scope, animate] = useAnimate<HTMLDivElement>();

  const { isOpened, value, options: optionsCtx } = useSelect();

  const opts = optionsCtx.length ? optionsCtx : options;

  const [classes, setClasses] = useState('');

  useLayoutEffect(() => {
    if (isOpened) {
      const enterAnimation = async (): Promise<void> => {
        const elementRect = scope.current?.getBoundingClientRect();

        if (elementRect) {
          const spaceAbove = elementRect.top;
          const spaceBelow = window.innerHeight - elementRect.bottom;

          const shouldOpenAbove = spaceBelow <= 0 && spaceBelow < spaceAbove;

          if (shouldOpenAbove) setClasses('bottom-11 top-auto');

          await animate(scope.current, { opacity: 1, y: shouldOpenAbove ? -8 : 8 }, { type: 'tween' });
          await animate(scope.current, { opacity: 1, y: 0 }, { type: 'tween' });
        }
      };

      enterAnimation();
    } else {
      setClasses('');
    }
  }, [animate, isOpened, scope]);

  return (
    <AnimatePresence mode="wait">
      {isOpened ? (
        <div
          ref={scope}
          className={twMerge(
            'absolute top-11 z-10 flex max-h-36 w-full flex-col overflow-y-auto rounded-lg border border-gray-200 bg-background-light shadow-lg scrollbar dark:border-black-400 dark:bg-background-dark',
            classes,
          )}
        >
          {withFilter && <SelectFilter />}

          {opts.length ? (
            <>
              {opts.map(option => (
                <SelectOption
                  key={optionKey ? option[optionKey] : option[value]}
                  option={option}
                  {...selectOptionProps}
                />
              ))}
            </>
          ) : (
            <div className="flex h-10 items-center justify-center">
              <Text className="text-center text-sm text-gray-400 dark:text-gray-500">{t('no_options')}</Text>
            </div>
          )}
        </div>
      ) : null}
    </AnimatePresence>
  );
};

SelectOptions.displayName = 'Select.Options';

// ------------------------------------------

const SelectHelpText: ISelectHelpTextProps = ({ children, className }): ReactElement => {
  return <Text className={twJoin('mt-1 block text-xs text-gray-400', className)}>{children}</Text>;
};

SelectHelpText.displayName = 'Select.HelpText';

// ------------------------------------------

export const Select = {
  Root: SelectRoot,
  Content: SelectContent,
  Filter: SelectFilter,
  HelpText: SelectHelpText,
  Input: SelectInput,
  InputRoot: SelectInputRoot,
  Options: SelectOptions,
};
